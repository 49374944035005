import React from 'react';
import { string } from 'prop-types';

import MobileAppsButtons from '../MobileAppsButtons';

import './style.scss';

const propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
};

const Header = ({ title, subtitle, ctaLabel }) => {
  return (
    <header className="Header">
      <h1 className="Header__title">{ title }</h1>
      <p className="Header__subtitle">{ subtitle }</p>
      <MobileAppsButtons className="Header__buttons" withText />
    </header>
  );
};

Header.propTypes = propTypes;

export default Header;
