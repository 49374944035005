import React from 'react';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import { arrayOf, shape, string } from 'prop-types';

import './style.scss';

const propTypes = {
  components: arrayOf(shape({
    title: string.isRequired,
    content: string.isRequired,
    image: shape({
      source: string.isRequired,
      alt: string,
    }).isRequired,
  })),
};

class WhyList extends React.Component {
  renderContentItem = ({ title, content, ctaLabel }) => {
    return (
      <div className="WhyList__content">
        <h3 className="WhyList__title">{ title }</h3>
        <ReactMarkdown className="WhyList__text" source={ content } />
      </div>
    );
  }

  render() {
    const { components } = this.props;

    return (
      <section className="WhyList">
        { components.map((feature, i) => {
          return (
            <div className={ cn('WhyList__item', { 'WhyList__item--reverse': i % 2 === 0 }) } key={ feature.title + i }>
              <div className="WhyList__image">
                <img src={ feature.image.source } alt={ feature.image.alt || feature.title } />
              </div>

              { this.renderContentItem(feature) }
            </div>
          );
        }) }
      </section>
    );
  }
};

WhyList.propTypes = propTypes;

export default WhyList;
