import React from 'react';
import { array, object } from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import WhyList from '../../components/WhyList';
import Seo from '../../components/Seo';

import './style.scss';

const propTypes = {
  header: object.isRequired,
  components: array.isRequired,
};

export const WhyPageTemplate = ({ header, components, seo }) => {

  return (
    <>
      <Seo { ...seo } />
      <section className="WhyPage">
        <Header { ...header } />
        <WhyList components={ components } />
      </section>
    </>
  );
};

const WhyPage = ({ data }) => {
  const { markdownRemark: { frontmatter } } = data;

  return (
    <Layout>
      <WhyPageTemplate
        { ...frontmatter }
      />
    </Layout>
  );
};

WhyPageTemplate.propTypes = propTypes;

WhyPage.propTypes = {
  data: object.isRequired,
};

export default WhyPage;

export const aboutPageQuery = graphql`
  query WhyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        header {
          title
          subtitle
        }
        components {
          title
          image {
            source
            alt
          }
          content
        }
      }
    }
  }
`;
